<template>
  <div class="buddha-type-content" >
    <div class="flex-box align-center buddha-type-area" v-if="info">
      <div class="buddha-type-info">
        <div class="buddha-type-name">{{info.name}}</div>
        <div class="buddha-type-other">{{getBuddhaCategoryName(info.cat)}}</div>
      </div>
      <div class="buddha-type-operate">
        <div class="type-operate-item">
          <span class="type-operate-title">线上登记</span>
          <a-switch checked-children="开启" un-checked-children="关闭" :loading="operateKey == 'status'" :checked="info.status == 1" @change="buddhaOpenChange($event, 'status')"/>
        </div>
        <div class="type-operate-item">
          <span class="type-operate-title">线下登记</span>
          <a-switch checked-children="开启" un-checked-children="关闭" :loading="operateKey == 'is_local'" :checked="info.is_local == 1" @change="buddhaOpenChange($event, 'is_local')"/>
        </div>
      </div>
    </div>
    <div class="buddha-type-area" style="min-height: 600px" v-if="info">
      <a-tabs :default-active-key="0">
        <a-tab-pane :tab="tab.title" v-for="tab in tabs" :key="tab.key">
          <div class="buddha-detail-piece" v-if="tab.key == 0">
            <div class="detail-info-item">
              <div class="detail-info-label">名称</div>
              <div class="detail-info-value">{{info.name}}<a-icon type="edit" class="buddha-edit-icon" @click="showEdit('name')"/></div>
            </div>
            <div class="detail-info-item">
              <div class="detail-info-label">所属大类</div>
              <div class="detail-info-value">{{getBuddhaCategoryName(info.cat)}}</div>
            </div>
            <div class="detail-info-item">
              <div class="detail-info-label">类别</div>
              <div class="detail-info-value">{{getBuddhaTypeName(info.fs_type)}}</div>
            </div>
            <div class="detail-info-item">
              <div class="detail-info-label">牌位上限</div>
              <div class="detail-info-value">{{info.maxTablet || '无上限'}}<a-icon type="edit" class="buddha-edit-icon" @click="showEdit('maxTablet')"/></div>
            </div>
            <div class="detail-info-item">
              <div class="detail-info-label">介绍</div>
              <div class="flex-box detail-info-value" style="align-items: flex-start">
                <div class="rich-preview" v-html="info.intro" v-if="info.intro"></div>
                <span v-else>无</span>
                <a-icon type="edit" class="buddha-edit-icon" @click="showEdit('intro')"/>
              </div>
            </div>
            <template v-if="hasExplanation(info.fs_type)">
              <template v-if="info.fs_type == 1">
                <div class="detail-info-item">
                  <div class="detail-info-label">单独普佛说明</div>
                  <div class="flex-box detail-info-value" style="align-items: flex-start">
                    <div class="rich-preview" v-html="info.singleDetail" v-if="info.singleDetail"></div>
                    <span v-else>无</span>
                    <a-icon type="edit" class="buddha-edit-icon" @click="showEdit('singleDetail')"/>
                  </div>
                </div>
                <div class="detail-info-item">
                  <div class="detail-info-label">众信普佛说明</div>
                  <div class="flex-box detail-info-value" style="align-items: flex-start">
                    <div class="rich-preview" v-html="info.groupDetail" v-if="info.groupDetail"></div>
                    <span v-else>无</span>
                    <a-icon type="edit" class="buddha-edit-icon" @click="showEdit('groupDetail')"/>
                  </div>
                </div>
              </template>
              <div class="detail-info-item" v-else>
                <div class="detail-info-label">说明</div>
                <div class="flex-box detail-info-value" style="align-items: flex-start">
                  <div class="rich-preview" v-html="info.detail" v-if="info.detail"></div>
                  <span v-else>无</span>
                  <a-icon type="edit" class="buddha-edit-icon" @click="showEdit('detail')"/>
                </div>
              </div>
            </template>
          </div>
          <div class="buddha-detail-piece" v-else-if="tab.key == 1">
            <buddha-time-set :temp="info"></buddha-time-set>
          </div>
          <div class="buddha-detail-piece" v-else-if="tab.key == 2">
            <chanting-set :value="info.chantingOptions" :edit="false" v-if="info.chantingOptions"></chanting-set>
            <div class="buddha-set-btn"><span v-if="!info.chantingOptions">尚未配置礼忏诵经选项，点击</span><span class="txt-btn" @click="showEdit('chantingOptions')">设置</span></div>
          </div>
          <div class="buddha-detail-piece" v-else-if="tab.key == 3">
            <div class="buddha-piece-title">支付配置</div>
            <pay-set :value="info.paySet" :type="info.fs_type" :single="single" :edit="false" v-if="info.paySet"></pay-set>
            <div class="buddha-set-btn"><span v-if="!info.paySet">尚未配置支付选项，点击</span><span class="txt-btn" @click="showEdit('paySet')">设置</span></div>
            <div class="gap"></div>
            <template v-if="price">
              <div class="buddha-piece-title">价格配置</div>
              <price-set :value="info.priceSet" :type="info.fs_type" :edit="false" v-if="info.priceSet"></price-set>
              <div class="buddha-set-btn"><span v-if="!info.priceSet">尚未配置价格，点击</span><span class="txt-btn" @click="showEdit('priceSet')">设置</span></div>
            </template>
          </div>
          <div class="buddha-detail-piece" v-else-if="tab.key == 4">
            <template v-if="info.subsidyConfig">
              <div class="detail-info-item" v-if="info.fs_type === 1">
                <div class="detail-info-label">津贴设置</div>
                <div class="detail-info-value">{{info.subsidyConfig.enable ? `津贴金额${info.subsidyConfig.amount}元` : '不参与津贴'}}</div>
              </div>
              <div class="detail-info-item" v-else>
                <div class="detail-info-label">{{ info.name }}</div>
              </div>
              <div class="detail-info-item" v-if="masterTypeList">
                <div class="detail-info-label">佛事单资</div>
                <div class="detail-info-value">
                  <buddha-grant :list="masterTypeList" :value="info.subsidyConfig.grant" :edit="false"></buddha-grant>
                </div>
              </div>
            </template>
            <div class="buddha-set-btn"><span v-if="!info.subsidyConfig">尚未配置单资选项，点击</span><span class="txt-btn" @click="showEdit('subsidyConfig')">佛事单资设置</span></div>
          </div>
          <div class="buddha-detail-piece" v-else-if="tab.key == 5">
            <div class="detail-info-item" v-if="info.foodSet">
              <div class="detail-info-label">填写设置</div>
              <div class="detail-info-value">对象为【个人】时最大可填写数量{{info.foodSet.maxNum}}个</div>
            </div>
            <div class="buddha-set-btn"><span v-if="!info.foodSet">尚未设置，点击</span><span class="txt-btn" @click="showEdit('foodSet')">设置</span></div>
          </div>
          <div class="buddha-detail-piece" v-else-if="tab.key == 6">
            <div class="flex-box">
              <div class="flex-grow">
                <div class="detail-info-item" v-if="info.pujaSet">
                  <div class="detail-info-label">法会开放日期</div>
                  <div class="detail-info-value">{{info.pujaSet[0]}}至{{info.pujaSet[1]}}</div>
                </div>
                <div class="buddha-set-btn"><span v-if="!info.pujaSet">法会开放日期尚未设置，点击</span><span class="txt-btn" @click="showEdit('pujaSet')">设置</span></div>
              </div>
              <div class="flex-grow">
                <div class="detail-info-item" v-if="info.registerSet">
                  <div class="detail-info-label">法会开放登记日期</div>
                  <div class="detail-info-value">{{info.registerSet[0]}}至{{info.registerSet[1]}}</div>
                </div>
                <div class="buddha-set-btn"><span v-if="!info.registerSet">法会开放登记日期尚未设置，点击</span><span class="txt-btn" @click="showEdit('registerSet')">设置</span></div>
              </div>
            </div>
            <div class="buddha-set-qr">
              <img :src="qr" class="set-qr-img" alt="" v-if="qr" />
              <div class="set-qr-img flex-box align-center justify-center" v-else><a-spin /></div>
              <a-button type="link" @click="downLoadQr">二维码下载</a-button>
            </div>
          </div>
          <div class="buddha-detail-piece" v-else-if="tab.key == 7">
            <chanting-set :value="info.chantingNumbers" :type="info.fs_type" :edit="false" v-if="info.chantingNumbers"></chanting-set>
            <div class="buddha-set-btn"><span v-if="!info.chantingNumbers">尚未设置诵经人数，点击</span><span class="txt-btn" @click="showEdit('chantingNumbers')">设置</span></div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <a-modal
      :title="title"
      :maskClosable="false"
      :closable="false"
      :destroyOnClose="true"
      :width="1000"
      @ok="confirmModal"
      v-model="visible">
      <pay-set :value="form.paySet" :type="info.fs_type" :single="single" ref="paySet" v-if="editKey == 'paySet'"></pay-set>
      <price-set :value="form.priceSet" :type="info.fs_type" ref="priceSet" v-else-if="editKey == 'priceSet'"></price-set>
      <form-area
        ref="modalForm"
        hide-btn
        link
        :items="items"
        :entity="form"
        v-else>
      </form-area>
    </a-modal>
  </div>
</template>

<script>
    import {
        getBuddhaCategoryName,
        hasExplanation,
        buddhaTypeSetValidator,
        getBuddhaTypeName,
        getBuddhaTypeTabs,
        getBuddhaTypeConfig,
        dealBuddhaTypeInfo
    } from "../../common/buddha/type";
    import { getBuddhismMaterTypeList } from "../../common/buddha/buddhism";
    import buddhaTimeSet from "../../components/buddha/buddha-time";
    import paySet from "../../components/buddha/pay-set";
    import priceSet from "../../components/buddha/price-set";
    import {clone, downLoadFile} from "../../common/js/tool";
    import config from "../../common/js/config";

    export default {
        name: "BuddhaTypeDetail",
        components: {
            buddhaTimeSet,
            paySet,
            priceSet
        },
        data() {
            return {
                info: null,
                form: {},
                editKey: null,
                specialValue: 1,
                specialTimeOptions: [
                    { label: '延生普佛', value: 1 },
                    { label: '往生普佛', value: 2 },
                ],
                visible: false,
                operateKey: null,
                qr: "", // 法会二维码
            }
        },
        computed: {
            id() {
                return this.$route.params.id;
            },
            title() {
                let res = '';
                switch (this.editKey) {
                    case 'name':
                        res = '修改名称';
                        break;
                    case 'intro':
                        res = '编辑介绍';
                        break;
                    case 'detail':
                        res = '编辑说明';
                        break;
                    case 'singleDetail':
                        res = '编辑单独普佛说明';
                        break;
                    case 'groupDetail':
                        res = '编辑众信普佛说明';
                        break;
                    case 'paySet':
                        res = '支付设置';
                        break;
                    case 'chantingOptions':
                        res = '礼忏诵经选项设置';
                        break;
                    case 'subsidyConfig':
                        res = '单资设置';
                        break;
                    case 'foodSet':
                        res = `${this.info.name}设置`;
                        break;
                    case 'pujaSet':
                        res = '法会日期设置';
                        break;
                    case 'registerSet':
                        res = '法会登记日期设置';
                        break;
                    case 'chantingNumbers':
                        res = '诵经人数设置';
                        break;
                    case 'priceSet':
                        res = '价格设置';
                        break;
                    case 'maxTablet':
                        res = '牌位上限设置';
                        break;
                }
                return res;
            },
            tabs() {
                let res = [];
                let info = this.info;
                if(!this.new && info) {
                    res = [
                        {key: 0, title: '基本信息'},
                    ].concat(getBuddhaTypeTabs(info.fs_type));
                }
                if(info.fs_type == 7 || info.fs_type == 8) {
                    res.push({key: 5, title: `${info.name}设置`})
                } else if(info.cat == 3) {
                    res.push({key: 6, title: '法会设置'});
                } else if(info.fs_type == 3) {
                    res.push({key: 7, title: '诵经人数设置'});
                }
                return res
            },
            items() {
                let res = [];
                const info = this.info;
                switch (this.editKey) {
                    case 'name':
                        res = [
                            {
                                key: 'name',
                                label: '',
                                component: 'a-input',
                                props: {
                                    placeholder: '请输入名称',
                                },
                                rules: [{ required: true, message: '请输入名称', trigger: 'blur' }]
                            },
                        ];
                        break;
                    case 'intro':
                        res = [
                            {
                                key: 'intro',
                                label: '',
                                component: 'rich-editor',
                                props: {
                                    placeholder: '请输入介绍'
                                },
                            },
                        ];
                        break;
                    case 'detail':
                    case 'singleDetail':
                    case 'groupDetail':
                        res = [
                            {
                                key: this.editKey,
                                label: '',
                                component: 'rich-editor',
                                props: {
                                    placeholder: '请输入说明'
                                },
                            },
                        ];
                        break;
                    case 'chantingOptions':
                        res = [
                            {
                                key: 'chantingOptions',
                                label: '',
                                component: 'chanting-set',
                                rules: [{validator: buddhaTypeSetValidator,trigger: 'change'}]
                            },
                        ];
                        break;
                    case 'subsidyConfig':
                        res = info.fs_type === 1 ? [
                            {
                                key: 'enable',
                                label: '是否参与津贴',
                                component: 'a-switch',
                                listeners: {
                                    change(val) {
                                        const index = this.controls.findIndex(c => c.key == "amount")
                                        if(val && index === -1) {
                                            this.controls.splice(1, 0, {
                                                key: 'amount',
                                                label: '津贴金额',
                                                component: 'a-input-number',
                                                props: {
                                                    placeholder: '请输入津贴金额',
                                                },
                                                style: {
                                                    width: '100%'
                                                },
                                                rules: [{ required: true, message: '请输入津贴金额', trigger: 'blur' }]
                                            })
                                        } else if(!val && index >= 0) {
                                            this.controls.splice(index, 1);
                                        }
                                    }
                                },
                            },
                        ] : [];
                        if(this.masterTypeList) {
                            res.push({
                                key: 'grant',
                                label: '佛事单资',
                                component: 'buddha-grant',
                                props: {
                                    list: this.masterTypeList
                                },
                                rules: [{validator: (rule, value, callback) => {
                                    if (value) {
                                        let error = null;
                                        if(this.masterTypeList) {
                                            for(let i = 0, l = value.length; i < l; i++) {
                                                let item = value[i];
                                                if(!item.grant) {
                                                    error = `请设置${item.name}的补助`;
                                                    break;
                                                }
                                            }
                                        }
                                        if (error) {
                                            callback(new Error(error));
                                        } else {
                                            callback()
                                        }
                                    } else {
                                        callback(new Error('请设置佛事单资'));
                                    }
                                }, trigger: 'blur'}]
                            })
                        }
                        break;
                    case 'foodSet':
                        res = [
                            {
                                key: 'maxNum',
                                label: '对象为【个人】时最大可填写数量',
                                component: 'a-input-number',
                                props: {
                                    placeholder: '请输入数量',
                                },
                                style: {
                                    width: '100%'
                                },
                                rules: [{ required: true, message: '请输入数量', trigger: 'blur' }]
                            },
                        ];
                        break;
                    case 'pujaSet':
                        res = [
                            {
                                key: 'pujaSet',
                                label: '法会开放日期',
                                component: 'a-range-picker',
                                props: {
                                    format: 'YYYY-MM-DD',
                                    valueFormat: 'YYYY-MM-DD',
                                    placeholder: ['开始时间', '截止时间']
                                },
                                rules: [{ required: true, message: '请设置法会开放日期', trigger: 'change' }]
                            },
                        ];
                        break;
                    case 'registerSet':
                        res = [
                            {
                                key: 'registerSet',
                                label: '登记开放日期',
                                component: 'a-range-picker',
                                props: {
                                    format: 'YYYY-MM-DD',
                                    valueFormat: 'YYYY-MM-DD',
                                    placeholder: ['开始时间', '截止时间']
                                },
                                rules: [{ required: true, message: '请设置法会登记开放日期', trigger: 'change' }]
                            },
                        ];
                        break;
                    case 'chantingNumbers':
                        res = [
                            {
                                key: 'chantingNumbers',
                                label: '',
                                component: 'chanting-set',
                                props: {
                                    type: 3
                                },
                                rules: [{validator: buddhaTypeSetValidator,trigger: 'change'}]
                            },
                        ];
                        break;
                    case 'maxTablet':
                        res = [
                            {
                                key: 'maxTablet',
                                label: '预约牌位数量上限',
                                component: 'a-input-number',
                                props: {
                                    placeholder: '请输入预约牌位数量上限',
                                },
                                style: {
                                    width: '100%',
                                    min: 1
                                },
                                rules: [{ required: true, message: '请输入预约牌位数量上限', trigger: 'blur' }]
                            },
                        ];
                        break;
                }
                return res;
            },
            config() {
                return this.info ? getBuddhaTypeConfig(this.info.fs_type) : null;
            },
            single() {
                let res = false;
                if(this.config && this.config.paySet == "single") {
                    res = true;
                }
                return res;
            },
            //是否需要设置价格
            price() {
                return !(this.config && this.config.noPrice);
            },
            masterTypeList() {
                let res = null;
                const config = this.config;
                if(config && [2,3].indexOf(config.key) >= 0 && config.subTypes) {
                    res = [];
                    config.subTypes.forEach(t => {
                        const isLc = ["05", "06"].includes(t.key);
                        const list = getBuddhismMaterTypeList({tabletType: t.key}).map(item => {
                            return {
                                ...item,
                                name: isLc && item.name === '师' ? '空板' : item.name
                            }
                        });
                        if(list) {
                            list.forEach(item => {
                                if(res.indexOf(item.name) === -1) {
                                    res.push(item.name)
                                }
                            })
                        }
                    })
                }
                return res;
            }
        },
        watch: {
            id() {
                if (this.$route.name !== 'BuddhaTypeDetail') return;
                this.init();
            },
        },
        created() {
            this.$store.dispatch("getBuddhaTimeRange");
            this.init();
        },
        methods: {
            getBuddhaCategoryName,
            hasExplanation,
            getBuddhaTypeName,
            downLoadQr() {
                if(this.qr) {
                    downLoadFile(this.qr, `${this.info.name}小程序码.png`)
                }
            },
            buddhaOpenChange(e, key) {
                this.operateKey = key;
                let url = `/admin/fs-temp/${this.info.id}`;
                let data = {};
                data[key] = e ? 1 : 0;
                this.$axios({
                    url,
                    method: 'PATCH',
                    data
                }).then(res => {
                    this.$set(this.info, key, res[key]);
                }).finally(() => {
                    this.operateKey = null;
                })
            },
            confirmModal() {
                if(this.editKey == 'paySet') {
                    this.$refs.paySet.checkForm().then(set => {
                        this.updateTemp({paySet: set, pay_config: JSON.stringify(set)});
                    })
                } else if(this.editKey == 'priceSet') {
                    let res = this.$refs.priceSet.getPriceConfig();
                    if(res.error) {
                        this.$message.warning(res.error);
                    } else {
                        this.updateTemp({priceSet: res, price_config: JSON.stringify(res)});
                    }
                } else {
                    this.$refs.modalForm.handleConfirm().then(form => {
                        let data = {...form};
                        let otherConfig = this.info.otherConfig || {};
                        switch (this.editKey) {
                            case "chantingOptions":
                                data.other_config = JSON.stringify({...otherConfig, ...form.chantingOptions});
                                break;
                            case "subsidyConfig":
                                data.subsidyConfig = {
                                    enable: !!form.enable
                                };
                                if(form.enable) {
                                    data.subsidyConfig.amount = form.amount;
                                }
                                if(this.masterTypeList) {
                                    data.subsidyConfig.grant = form.grant;
                                }
                                data.allowance_config = JSON.stringify(data.subsidyConfig);
                                break;
                            case "foodSet":
                                data = {
                                    other_config: JSON.stringify({...otherConfig, ...form}),
                                    foodSet: form
                                }
                                break;
                            case "pujaSet":
                                data.other_config = JSON.stringify({...otherConfig, begin: form.pujaSet[0], end: form.pujaSet[1]});
                                break;
                            case "registerSet":
                                data.other_config = JSON.stringify({...otherConfig, register_begin: form.registerSet[0], register_end: form.registerSet[1]});
                                break;
                            case "chantingNumbers":
                                data.other_config = JSON.stringify({...otherConfig, ...form.chantingNumbers});
                                break;
                            case "maxTablet":
                                data.other_config = JSON.stringify({...otherConfig, maxTablet: form.maxTablet});
                                break;
                            case 'singleDetail':
                            case 'groupDetail':
                                data.detail = JSON.stringify({
                                    ...{ singleDetail: this.info.singleDetail, groupDetail: this.info.groupDetail },
                                    [this.editKey]: form[this.editKey]
                                })
                                break;
                        }
                        this.updateTemp(data);
                    });
                }
            },
            updateTemp(data) {
                let url = `/admin/fs-temp/${this.info.id}`;
                return this.$axios({
                    url,
                    method: 'PATCH',
                    data
                }).then(() => {
                    this.info = {...this.info, ...data};
                    this.visible = false;
                })
            },
            showEdit(key) {
                let form = {}
                if(key == 'subsidyConfig' && this.info.subsidyConfig) {
                    form = {...this.info.subsidyConfig};
                } else if(key == 'foodSet' && this.info.foodSet) {
                    form = {...this.info.foodSet};
                } else {
                    form[key] = this.info[key];
                }
                this.editKey = key;
                this.form = form;
                this.visible = true;
                // 触发显示津贴金额表单 待优化
                this.$nextTick(() => {
                    if(key == 'subsidyConfig') {
                        this.items[0].listeners.change.call(this.$refs.modalForm, form.enable);
                    }
                })
            },
            init() {
                if(this.new) {
                    this.setEdit();
                } else {
                    this.getDetail();
                }
            },
            setEdit() {
                let info = this.info;
                if(info) {
                    this.form = clone(info);
                } else {
                    this.form = {
                        subsidyEnable: false
                    }
                }
                this.edit = true;
            },
            getDetail() {
                let id = this.id;
                if (!id) return;
                let url = `/admin/fs-temp/${id}`;
                this.$axios(url).then(this.dealInfo);
            },
            dealInfo(info) {
                dealBuddhaTypeInfo(info);
                this.info = info;
                if (info.cat === 3) {
                    this.getQr();
                }
                if(this.$route.query.edit == "1") {
                    this.setEdit();
                } else {
                    this.edit = false;
                }
            },
            getQr() {
                this.$axios({
                    url: '/mini/common/share-code',
                    method: 'POST',
                    data: {
                        path: `pages/buddhism/fahui/fahui?id=${this.info.id}`
                    }
                }).then(res => {
                    const url = `${config.host}/${res.data}`;
                    this.qr = url;
                })
            },
            getParam(form, paySet) {
                let data = {
                    cat: form.cat,
                    fs_type: form.fs_type,
                    name: form.name,
                    intro: form.intro,
                    detail: form.detail,
                }
                data.reserve_config = JSON.stringify(form.reserveTime);
                if(form.chantingOptions) {
                    data.other_config = JSON.stringify(form.chantingOptions);
                }
                form.paySet = paySet;
                data.pay_config = JSON.stringify(paySet);
                let payConf = {
                    enable: form.subsidyEnable
                };
                if(form.subsidyEnable) {
                    payConf.amount = form.subsidyAmount
                }
                data.allowance_config = JSON.stringify(payConf);
                if(form.id) {
                    data.id = form.id;
                }
                return data;
            },
            checkForm() {
                return new Promise((resolve, reject) => {
                    this.$refs.buddhaTypeForm.validate(valid => {
                        if (valid) {
                            resolve(this.form);
                        } else {
                            reject("form error");
                        }
                    });
                })
            }
        }
    }
</script>

<style scoped lang="less">
  .type-operate-item {
    display: flex;
    align-items: center;
    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
  .type-operate-title {
    margin-right: 8px;
  }
  .buddha-type-content {
    margin: 0 auto;
    width: 1200px;
  }
  .buddha-type-area {
    margin: 20px 0;
    padding: 0 20px;
    background-color: @body-background;
    box-shadow: @box-shadow-base;
  }
  .buddha-type-info {
    flex: 1;
    padding: 20px 0;
  }
  .buddha-type-name {
    font-size: 20px;
    font-weight: 600;
  }
  .buddha-type-other {
    margin-top: 6px;
    color: @text-color-secondary;
  }
  .buddha-edit-icon {
    margin: 2px 0 0 0.5em;
    cursor: pointer;
    color: @text-color-secondary;
  }
  .buddha-type-detail,
  .buddha-type-ctx {
    flex: 1;
    min-height: 0;
    overflow: auto;
  }
  .buddha-type-piece {
    position: relative;
  }
  .time-radio {
    position: absolute;
    top: 3px;
    left: 100px;
  }
  .content .footer {
    padding-bottom: 0;
  }
  .buddha-detail-piece {
    padding: 0 16px 16px;
    font-size: 14px;
    .detail-info-item {
      font-size: 14px;
    }
  }
  .buddha-piece-title {
    color: @orange-6;
  }
  .buddha-set-btn {
    margin-top: 8px;
  }
  .buddha-set-qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    width: 200px;
  }
  .set-qr-img {
    margin-bottom: 16px;
    width: 200px;
    height: 200px;
  }
</style>
